import { Event, Thumbnail, Campaign, CampaignFormField, CampaignApplicationStatus, Price } from '@photocreate/snapsnap-api'
import { IEventProps } from '@/entities/Event'
import { ICampaignStatusProps } from '@/entities/CampaignStatus'
import { IEventThumbnail, IEventPhotoPrices } from '@/types/domain/event'
import { ICartQueryStatusProps } from '@/entities/C4'
import { IEventCampaignProps, IEventCampaignField } from '@/entities/EventCampaign'
import { toPriceProps } from './price'

export const toEventProps = (event: Event): IEventProps => {
  return {
    id: event.id,
    name: event.name,
    // TODO: eventStartDt, eventEndDtは空文字だとdayjsがperseできないのでnullableにする
    eventStartDt: event.from_dt || '',
    eventEndDt: event.to_dt || '',
    orderStartDt: event.order_start_dt,
    orderEndDt: event.order_end_dt,
    schoolShipFlag: event.school_ship_flag,
    sponsoredPrintFlag: event.sponsored_print_flag,
    not4saleFlag: event.not4sale_flag,
    moovinFlag: event.moovin_flag,
    publishStatus: event.publish_status,
    notRepublicFlag: event.not_republic_flag || false,
    passwordUnlockFlag: event.password_unlock_flag || false,
    passwordType: event.password_type || '',
    leadText: event.lead_text,
    defaultCaption: event.default_caption,
    announcementText: event.announcement_text,
    selflaboFlag: event.selflabo_flag,
    not4postcardFlag: event.not4postcard_flag,
    downloadSetFlag: event.download_set_flag,
    spotlightFlag: event.spotlight_flag,
    premiumRate: event.premium_rate
  }
}

export const toEventThumbnail = (thumbnail: Thumbnail): IEventThumbnail => {
  const { event_id, album_photo_urls, uchinoko_urls } = thumbnail
  return {
    eventId: event_id,
    albumPhotoUrls: album_photo_urls,
    uchinokoUrls: uchinoko_urls
  }
}

export const toEventCampaignField = (field: CampaignFormField): IEventCampaignField => {
  const { id, label, field_type, required, choices } = field

  return {
    id,
    label,
    fieldType: field_type,
    required,
    choices
  }
}

export const toEventCampaignProps = (campaign: Campaign): IEventCampaignProps => {
  const { campaign_id, campaign_code, campaign_name, target_prefectures, campaign_form_fields } = campaign

  return {
    id: campaign_id,
    campaignCode: campaign_code,
    name: campaign_name,
    targetPrefectures: target_prefectures,
    fields: campaign_form_fields.map(toEventCampaignField)
  }
}

export const toCampaignStatusProps = (campaignStatus: CampaignApplicationStatus): ICampaignStatusProps => {
  const { campaign_code, campaign_id, is_applied } = campaignStatus
  return {
    campaignCode: campaign_code,
    campaignId: campaign_id,
    isApplied: is_applied
  }
}

export const toEventPhotoPricesProps = (photoPrices: { prices: Price[]; photo_ids: number[] }): IEventPhotoPrices => {
  const { prices, photo_ids } = photoPrices
  return {
    prices: prices.map(toPriceProps),
    photoIds: photo_ids
  }
}

export const toCartQueryProps = (cartQuerystatus: { has_cart_query: boolean; has_order_history: boolean }): ICartQueryStatusProps => {
  const { has_cart_query, has_order_history } = cartQuerystatus
  return {
    hasCartQuery: has_cart_query,
    hasOrderHistory: has_order_history
  }
}
